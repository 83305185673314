<template>
    <!-- background-color="rgb(48, 65, 86)" -->
    <el-menu :default-active="$route.path"
             :collapse-transition="false"
             :collapse="isCollapse"
             @select="handleSelect"
             :router="false"
             background-color="#000"
             text-color="#fff"
             active-text-color="#ffd04b"
             style="min-height: 100%; overflow-x: hidden"
    >
        <div style="height: 60px; line-height: 60px; text-align: center">
            <img src="../assets/liveIcon.png" alt="" style="width: 20px; position: relative; top: 5px;">
            <b style="color: white; margin-left: 5px" v-show="logoTextShow">网剧推广</b>
        </div>
        <div v-for="item in menus" :key="item.id">
            <div v-if="item.path">
                <el-menu-item :index="adminPage +item.path">
                    <i :class="item.icon"></i>
                    <span slot="title">{{ item.name }}</span>
                </el-menu-item>
            </div>
            <div v-else>
                <el-submenu :index="item.id + ''">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.name }}</span>
                    </template>
                    <div  v-for="subItem in item.children" :key="subItem.id">
                        <el-menu-item :index="adminPage +subItem.path">
                            <i :class="subItem.icon"></i>
                            <span slot="title">{{ subItem.name }}</span>
                        </el-menu-item>
                    </div>
                </el-submenu>
            </div>
        </div>
    </el-menu>
</template>

<script>
    import { ADMIN_PAGE } from '@/utils/constants';
    export default {
        name: "Aside",
        props: {
            isCollapse: Boolean,
            logoTextShow: Boolean
        },
        data() {
            return {
                adminPage: ADMIN_PAGE, // 确保这个常量在数据对象中定义为响应式
                menus: localStorage.getItem("menus") ? JSON.parse(localStorage.getItem("menus")) : [],
            }
        },
        methods: {
            handleSelect(key, keyPath) {
                this.$router.push(key);
            }
        }
    }
</script>

<style>
    .el-menu-item.is-active {
        background-color: rgb(38, 52, 69) !important;
    }
    .el-menu-item:hover {
        background-color: rgb(38, 52, 69) !important;
    }

    .el-submenu__title:hover {
        background-color: rgb(38, 52, 69) !important;
    }
    /*解决收缩菜单文字不消失问题*/
    .el-menu--collapse span {
        visibility: hidden;
    }
</style>
