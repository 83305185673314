<template>
  <div style="line-height: 60px; display: flex">
    <div style="flex: 1;">
      <span :class="collapseBtnClass" style="cursor: pointer; font-size: 18px" @click="collapse"></span>

      <el-breadcrumb separator="/" style="display: inline-block; margin-left: 10px">
        <el-breadcrumb-item :to="'/'">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ currentPathName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div id="time" style="width: 500px; text-align: center">{{time}}</div>
    <div>
    欢迎你！
    <template v-for="item in roles">
      <el-tag type="success" size="medium " effect="plain" v-if="user.role==item.flag">
      <span style="font-size: 14px; padding: 5px 0 ;">
      {{item.name}}
      </span>
      </el-tag>
    </template>
    </div>
    <el-dropdown style="width: 150px; cursor: pointer; text-align: right">
      <div style="display: inline-block">
        <img v-if="user.avatarUrl" :src="user.avatarUrl" 
             style="width: 30px;height: 30px; border-radius: 50%; position: relative; top: 10px; right: 5px">
        <img v-else src="../assets/1.png" 
             style="width: 30px;height: 30px; border-radius: 50%; position: relative; top: 10px; right: 5px">     
        <span>{{ user.nickname }}</span><i class="el-icon-arrow-down" style="margin-left: 5px"></i>
      </div>
      <el-dropdown-menu slot="dropdown" style="width: 100px; text-align: center">
  <!--      <el-dropdown-item style="font-size: 14px; padding: 5px 0">
          <router-link to="/password">修改密码</router-link>
        </el-dropdown-item>
        <el-dropdown-item style="font-size: 14px; padding: 5px 0">
          <router-link to="/person">个人信息</router-link>
        </el-dropdown-item> -->
        <el-dropdown-item style="font-size: 14px; padding: 5px 0" @click.native="logout">
          <span style="text-decoration: none" @click="logout">退出</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    collapseBtnClass: String,
    user: Object
  },
  computed: {
    currentPathName () {
      return this.$store.state.currentPathName;　　//需要监听的数据
    }
  },
  data() {
    return {
        time:'',
        roles:[],
    }
  },
  created() {
    this.roleList();
    this.getCurrentTime();
    setInterval(() => {
      this.getCurrentTime(); //每秒更新一次时间
    }, 1000);
  },
  methods: {
    roleList(){
      this.request.get("/role").then(res => {
        this.roles = res.data
      })
    },
    collapse() {
      // this.$parent.$parent.$parent.$parent.collapse()  // 通过4个 $parent 找到父组件，从而调用其折叠方法
      this.$emit("asideCollapse")
    },
    logout() {
      this.$store.commit("logout")
      this.$message.success("退出成功")
    },

    getCurrentTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      this.time = `${year}年${month}月${day}日 周${this.getWeekday(now.getDay())} ${hours}:${minutes}:${seconds}`;
    },
    getWeekday(dayIndex) {
      const weekdays = ['日', '一', '二', '三', '四', '五', '六'];
      return weekdays[dayIndex];
    },
  }
}
</script>

<style scoped>
</style>
